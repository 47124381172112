/* COLORS */
$white: #ffffff;
$orange: #f68f22;
$orange-hover: #dd7d18;
$bg-orange: #ffd3a8;
$black: #1f2937;
$gray-dark: #4b5563;
$gray-light: #6b7280;
$bg-body: #f7f7f8;
$green: #34d399;
$red: #ee0004;

/* FONTS */
$font-family: 'Poppins', sans-serif;
$font-family-table: 'Roboto', sans-serif;

/* GENERAL */
$font-size-general: 1rem;
$line-height-general: 1.5rem;

/* HEADER */
$font-size-header: 1.5rem;
$line-height-header: 2.25rem;

/* TABLE */
$font-size-table-header: 0.75rem;
$padding-table-header: 0 1.5rem;

$font-size-table-data: 0.875rem;
$padding-table-data: 1rem 1.5rem;
