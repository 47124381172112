@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);

  $background: map.get($config, background);

  .jp-table {
    &-loader {
      background-color: rgba(map.get($background, background), 0.6);
    }

    .row {
      &--default:hover {
        background-color: map.get($background, hover) !important;
      }

      &--selected {
        background-color: map.get($background, hover) !important;
      }
    }

    .grouped-header {
      background-color: map.get($background, hover) !important;
    }

    .mat-header-cell {
      font-weight: bold;
      font-size: 13px;
    }
  }
}

@mixin density($config-or-theme) {}

@mixin typography($config-or-theme) {}

@mixin theme($theme) {
  $color: mat.get-color-config($theme);
  $density: mat.get-density-config($theme);
  $typography: mat.get-typography-config($theme);

  @if $color !=null {
    @include color($color);
  }

  @if $density !=null {
    @include density($density);
  }

  @if $typography !=null {
    @include typography($typography);
  }
}