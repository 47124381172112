.jp-form {
  display: grid;
  row-gap: 0.5rem;
}

.jp-form .form-section {
  &__title {
    font-weight: 300;
  }

  &__group {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-auto-columns: minmax(0, 1fr);
    gap: 0.5rem 1.25rem;
  }

  &__divider {
    margin-bottom: 1rem;
  }
}
