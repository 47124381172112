@use 'sass:map';
@use '@angular/material' as mat;

@use 'styles/themes/light';
@use './app/table/components/table/table-theme';

@use 'styles/forms' as *;

@import 'assets/scss/variables';
@import 'assets/scss/mixins';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@include mat.core();

@include mat.core-theme(light.$theme);
@include mat.all-component-themes(light.$theme);
@include table-theme.theme(light.$theme);

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.custom-dialog-container .mat-dialog-container {
  padding: 0 !important;
}

.flex-20 {
  flex-basis: 20%;
}
