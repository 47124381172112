@mixin ratio($width, $height) {
    position: relative;

    &:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }
}

@mixin mat-table-columns($columns) {
    .mat-column- {
        @each $colName, $props in $columns {
            $width: map-get($props, 'width');
            &#{$colName} {
                flex: 0 0 $width;
                width: $width;
                max-width: 0;
                @if ($colName != 'selected') {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

@mixin field-key-column($property, $expression) {
    #{$property}: -moz-calc(#{$expression});
    #{$property}: -webkit-calc(#{$expression});
    #{$property}: calc(#{$expression});
}

div,
p,
span,
img,
a {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}
